<template>
  <div>
    <c-table
      ref="table"
      title="통계조사표"
      tableId="table"
      :columns="grid.columns"
      :data="grid.data"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'statistics',
  components: {
  },
  props: {
    searchParam: {
      type: Object,
      default: function() {
        return {
          plantCd: null,
          year: '',
        };
      },
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      grid: {
        columns: [
          {
            fix: true,
            name: 'materialCd',
            field: 'materialCd',
            label: '자재코드',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            fix: true,
            name: 'materialName',
            field: 'materialName',
            label: '화학자재',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'productCategoryName',
            field: 'productCategoryName',
            label: '제품구분',
            align: 'center',
            style: 'width:250px',
            sortable: false,
          },
          {
            name: 'mixName',
            field: 'mixName',
            label: '제품구성',
            align: 'center',
            style: 'width:90px',
            sortable: false,
          },
          {
            name: 'propertiesStateName',
            field: 'propertiesStateName',
            label: '성상',
            align: 'center',
            style: 'width:70px',
            sortable: false,
          },
          {
            name: 'usageName',
            field: 'usageName',
            label: '용도',
            align: 'center',
            style: 'width:150px',
            sortable: false,
          },
          {
            name: 'productManufClassName',
            field: 'productManufClassName',
            label: '제품제조구분',
            align: 'center',
            style: 'width:90px',
            sortable: false,
          },
          {
            name: 'chemIngredientsName',
            field: 'chemIngredientsName',
            label: '구성성분<br/>작성근거',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'keepFacilityFlag',
            field: 'keepFacilityFlag',
            label: '보관시설<br/>(창고)<br/>유무',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'maxKeepAmount',
            field: 'maxKeepAmount',
            label: '최대보관량(톤)',
            align: 'right',
            style: 'width:90px',
            sortable: false,
            type: 'cost',
          },
          {
            name: 'storageFacilityFlag',
            field: 'storageFacilityFlag',
            label: '저장시설<br/>(탱크)<br/>유무',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'maxStorageAmount',
            field: 'maxStorageAmount',
            label: '최대저장량(톤)',
            align: 'right',
            style: 'width:90px',
            sortable: false,
            type: 'cost',
          },
          {
            name: 'manufacturingFacilitiesFlag',
            field: 'manufacturingFacilitiesFlag',
            label: '제조·사용시설<br/>여부',
            align: 'center',
            style: 'width:90px',
            sortable: false,
          },
          {
            name: 'nanoManufacturingFlag',
            field: 'nanoManufacturingFlag',
            label: '나노물질<br/>제조',
            align: 'center',
            style: 'width:90px',
            sortable: false,
          },
          {
            name: 'nanoIncomeFlag',
            field: 'nanoIncomeFlag',
            label: '나노물질<br/>수입',
            align: 'center',
            style: 'width:90px',
            sortable: false,
          },
          {
            name: 'nanoNoneApplicableFlag',
            field: 'nanoNoneApplicableFlag',
            label: '나노물질<br/>해당없음',
            align: 'center',
            style: 'width:90px',
            sortable: false,
          },
          {
            name: 'nanoUnknownFlag',
            field: 'nanoUnknownFlag',
            label: '나노물질<br/>모름',
            align: 'center',
            style: 'width:90px',
            sortable: false,
          },
          {
            name: 'unit',
            field: 'unit',
            label: '입출고<br/>단위',
            align: 'center',
            style: 'width:90px',
            sortable: false,
          },
          {
            name: 'inYear',
            field: 'inYear',
            label: '입고량(kg/년)',
            align: 'center',
            child: [
              {
                name: 'in1',
                field: 'in1',
                label: '제조',
                align: 'right',
                style: 'width:50px',
                sortable: false,
                type: 'cost',
              },
              {
                name: 'in2',
                field: 'in2',
                label: '수입',
                align: 'right',
                style: 'width:50px',
                sortable: false,
                type: 'cost',
              },
              {
                name: 'in3',
                field: 'in3',
                label: '구매',
                align: 'right',
                style: 'width:50px',
                sortable: false,
                type: 'cost',
              },
              {
                name: 'carryover',
                field: 'carryover',
                label: '이월',
                align: 'right',
                style: 'width:50px',
                sortable: false,
                type: 'cost',
              },
              {
                name: 'inSum',
                field: 'inSum',
                label: '합계',
                align: 'right',
                style: 'width:50px',
                sortable: false,
                type: 'cost',
              },
            ]
          },
          {
            name: 'outYear',
            field: 'outYear',
            label: '출고량(kg/년)',
            align: 'center',
            child: [
              {
                name: 'out1',
                field: 'out1',
                label: '사용',
                align: 'right',
                style: 'width:50px',
                sortable: false,
                type: 'cost',
              },
              {
                name: 'out2',
                field: 'out2',
                label: '판매',
                align: 'right',
                style: 'width:50px',
                sortable: false,
                type: 'cost',
              },
              {
                name: 'out3',
                field: 'out3',
                label: '수출',
                align: 'right',
                style: 'width:50px',
                sortable: false,
                type: 'cost',
              },
              {
                name: 'inventory',
                field: 'inventory',
                label: '재고',
                align: 'right',
                style: 'width:50px',
                sortable: false,
                type: 'cost',
              },
              {
                name: 'out4',
                field: 'out4',
                label: '손실/폐기',
                align: 'right',
                style: 'width:50px',
                sortable: false,
                type: 'cost',
              },
              {
                name: 'outSum',
                field: 'outSum',
                label: '합계',
                align: 'right',
                style: 'width:50px',
                sortable: false,
                type: 'cost',
              },
            ]
          },
          {
            name: 'vendorName',
            field: 'vendorName',
            label: '공급업체',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.chm.inout.statistics.url;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
  }
};
</script>